import React, { createContext, useState } from 'react'

export const UserContext = createContext<any>(null)

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<any>(null)
  const [merchState, setMerchState] = useState()

  // Get current job
  const currentJob = React.useMemo(() => {
    if (!user?.employments?.length) {
      return null
    }

    const now = new Date()
    const validJobs = user.employments.map((job: any) => ({
      ...job,
      isValid:
        (!job.startdate || new Date(job.startdate) <= now) &&
        (!job.enddate || new Date(job.enddate) >= now)
    }))

    // Sort by start date descending
    const sortedJobs = validJobs.sort(
      (a: any, b: any) =>
        new Date(b.startdate || 0).getTime() - new Date(a.startdate || 0).getTime()
    )

    // Priority 1: Valid and primary
    const validPrimary = sortedJobs.find((job: any) => job.isValid && job.els_paatoimi === true)
    if (validPrimary) {
      return validPrimary
    }

    // Priority 2: Valid and not primary
    const validSecondary = sortedJobs.find((job: any) => job.isValid)
    if (validSecondary) {
      return validSecondary
    }

    // Priority 3: Most recent regardless of validity
    return sortedJobs[0]
  }, [user?.employments])

  const value = { user, setUser, merchState, setMerchState, currentJob }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContextProvider
