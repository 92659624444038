import React, { useContext, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { isMobile } from 'react-device-detect'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import {
  ShadowTableRow,
  ContainerHeaderH1,
  InfoContainer
} from '../../components/styled/uiComponents'
import { buildApiUrl, parseDate } from '../../utils/utils'
import { AuthContext } from '../../context/AuthProvider'
import { isSessionInvalidError } from '../../utils/error'
import Divider from './Divider'
import MobileTitle from './MobileTitle'

interface Application {
  id: string
  applicationType: string
  state: string
  submitted: string
  handled?: string
}

const Applications = () => {
  const [applications, setApplications] = useState<Application[]>([])
  const { bearerToken, handleLogout } = useContext(AuthContext)
  const [isOpen, setIsOpen] = useState(!isMobile)

  const navigate = useNavigate()

  const fetchApplications = async () => {
    try {
      const res = await axios.get(buildApiUrl('applications'), {
        headers: { 'x-tietoni-token': `Bearer ${bearerToken}` }
      })
      setApplications(res.data)
    } catch (error) {
      console.log(error)
      if (isSessionInvalidError(error)) {
        toast.error('Istunto on päättynyt. Kirjaudu uudelleen sisään.')
        handleLogout(() => navigate('/'))
      }
    }
  }

  useEffect(() => {
    fetchApplications()
  }, [])

  const translateApplicationTypes = (applicationType: string) => {
    if (applicationType === 'STUDENT') {
      return 'Ilmoita tietosi Lääkäriliittoon'
    } else if (applicationType === 'MEMBER') {
      return 'Jäsenhakemus'
    } else if (applicationType === 'RESIGNATION') {
      return 'Eroilmoitus'
    } else if (applicationType === 'DISCOUNT') {
      return 'Jäsenmaksualennushakemus'
    } else {
      return applicationType
    }
  }

  return applications.length === 0 ? (
    <></>
  ) : (
    <>
      <Divider id='applications' isMobile={isMobile} />
      <InfoContainer>
        <Grid container alignItems='center'>
          {isMobile ? (
            <MobileTitle isOpen={isOpen} setIsOpen={setIsOpen} title='Hakemukset' />
          ) : (
            <Grid item xs={12}>
              <ContainerHeaderH1>Hakemukset</ContainerHeaderH1>
            </Grid>
          )}
          {isOpen && (
            <Grid container alignItems='center'>
              {applications.length > 0 &&
                applications.map((application: Application) => (
                  <ShadowTableRow key={application.id}>
                    <Grid
                      container
                      direction={isMobile ? 'column' : 'row'}
                      alignItems={isMobile ? 'start' : 'center'}>
                      <Grid item xs={12} md={3}>
                        <h3>Hakemustyyppi</h3>
                        <p>{translateApplicationTypes(application.applicationType)}</p>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <h3>Lähetetty</h3>
                        <p>{parseDate(application.submitted)}</p>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <h3>Käsitelty</h3>
                        <p>
                          {application.handled
                            ? parseDate(application.handled)
                            : 'Ei vielä käsitelty'}
                        </p>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <h3>Tila</h3>
                        <p>{application.state}</p>
                      </Grid>
                    </Grid>
                  </ShadowTableRow>
                ))}
            </Grid>
          )}
        </Grid>
      </InfoContainer>
    </>
  )
}

export default Applications
