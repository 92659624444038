import React from 'react'
import { useController, Control } from 'react-hook-form'
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  FormHelperText
} from '@mui/material'
import { AttachFile, Delete, PictureAsPdf, Image } from '@mui/icons-material'

interface FileInputProps {
  name: string
  control: Control<any>
  required?: boolean
  disabled?: boolean
}

const FileInput: React.FC<FileInputProps> = ({
  name,
  control,
  required = false,
  disabled = false
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null)

  const {
    field: { onChange, value = [] },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules: {
      required: required && 'This field is required',
      validate: {
        maxFiles: (files: File[]) => files.length <= 5 || 'Maximum 5 files allowed',
        fileType: (files: File[]) => {
          const validTypes = ['application/pdf', 'image/jpeg', 'image/png']
          return (
            files.every(file => validTypes.includes(file.type)) ||
            'Only PDF, JPG, and PNG files are allowed'
          )
        }
      }
    }
  })

  const MAX_FILES = 5
  const acceptedTypes = '.pdf,.jpg,.jpeg,.png'

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || [])
    const updatedFiles = [...value, ...newFiles]

    if (updatedFiles.length <= MAX_FILES) {
      onChange(updatedFiles)
    }

    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const handleRemoveFile = (index: number) => {
    const updatedFiles = value.filter((_: File, i: number) => i !== index)
    onChange(updatedFiles)
  }

  const formatFileSize = (bytes: number): string => {
    if (bytes === 0) {
      return '0 Bytes'
    }
    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`
  }

  const getFileIcon = (fileType: string) => {
    if (fileType === 'application/pdf') {
      return <PictureAsPdf />
    }
    if (fileType.startsWith('image/')) {
      return <Image />
    }
    return <AttachFile />
  }

  return (
    <div>
      <input
        ref={fileInputRef}
        type='file'
        multiple
        accept={acceptedTypes}
        style={{ display: 'none' }}
        onChange={handleFileSelect}
        id={`file-input-${name}`}
        disabled={disabled || value.length >= MAX_FILES}
        capture
      />
      <label htmlFor={`file-input-${name}`}>
        <Button
          variant='contained'
          component='span'
          startIcon={<AttachFile />}
          disabled={disabled || value.length >= MAX_FILES}>
          Lisää tiedostoja
        </Button>
      </label>

      <Typography variant='caption' display='block' sx={{ mt: 1, mb: 2 }}>
        Hyväksytyt tiedostomuodot: PDF, JPG, PNG (enintään {MAX_FILES} tiedostoa)
      </Typography>

      {/* {error && <FormHelperText error>{error.message}</FormHelperText>} */}

      {value.length > 0 && (
        <List>
          {value.map((file: File, index: number) => (
            <ListItem key={`${file.name}-${index}`}>
              <ListItemIcon>{getFileIcon(file.type)}</ListItemIcon>
              <ListItemText
                primary={file.name}
                secondary={`${formatFileSize(file.size)} - ${file.type
                  .split('/')[1]
                  .toUpperCase()}`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => handleRemoveFile(index)}
                  disabled={disabled}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  )
}

export default FileInput
