import { Grid } from '@mui/material'
import React from 'react'
import { LaakariButtonNarrow, ShadowTableRow } from '../../../components/styled/uiComponents'
import { Job } from '../../../types/job'
import { parseDate } from '../../../utils/utils'

interface Props {
  job: Job
  editAction: () => void
  deleteAction: () => void
  isMobile: boolean
  fullScreen?: any
}

const canDelete = (job: Job): boolean => {
  return new Date().getTime() - new Date(job.createdon).getTime() < 24 * 60 * 60 * 1000
}

const WorkItem: React.FC<Props> = ({ job, editAction, deleteAction, isMobile, fullScreen }) => (
  <ShadowTableRow>
    <Grid container direction={isMobile || fullScreen ? 'column' : 'row'}>
      <Grid item xs={12} md={2}>
        <h3>Työnantaja ja työpaikka</h3>
        <p>{job.els_nimi || 'Tietoa ei löytynyt'}</p>
      </Grid>
      <Grid item xs={12} md={2}>
        <h3>Nimike</h3>
        <p>{job.ammattinimike?.els_koodinnimi}</p>
      </Grid>
      <Grid item xs={12} md={2}>
        <h3>Päätoiminen</h3>
        <p>{job.els_paatoimi ? 'Kyllä' : 'Ei'}</p>
      </Grid>
      {job.els_loppupvm ? (
        <>
          <Grid item xs={12} md={2}>
            <h3>Aloittanut</h3>
            <p>{job.els_alkupvm && parseDate(job.els_alkupvm)}</p>
          </Grid>
          <Grid item xs={12} md={2}>
            <h3>Päättynyt</h3>
            <p>{parseDate(job.els_loppupvm)}</p>
          </Grid>
        </>
      ) : (
        <Grid item xs={12} md={4}>
          <h3>Aloittanut</h3>
          <p>{job.els_alkupvm && parseDate(job.els_alkupvm)}</p>
        </Grid>
      )}
      {isMobile || fullScreen ? (
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={2}>
          <Grid item xs={6}>
            <LaakariButtonNarrow onClick={() => editAction()} variant='outlined'>
              Muokkaa
            </LaakariButtonNarrow>
          </Grid>
          <Grid item xs={6}>
            {canDelete(job) && (
              <LaakariButtonNarrow onClick={() => deleteAction()} variant='outlined'>
                Poista
              </LaakariButtonNarrow>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          xs={2}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'right'
          }}>
          <div style={{ marginBottom: '8px' }}>
            <LaakariButtonNarrow onClick={() => editAction()} variant='outlined'>
              Muokkaa
            </LaakariButtonNarrow>
          </div>
          <div>
            {canDelete(job) && (
              <LaakariButtonNarrow onClick={() => deleteAction()} variant='outlined'>
                Poista
              </LaakariButtonNarrow>
            )}
          </div>
        </Grid>
      )}
    </Grid>
  </ShadowTableRow>
)

export default WorkItem
