import React, { useState } from 'react'
import { FormLabel, Grid, Typography } from '@mui/material'

import { Control, UseFormRegister } from 'react-hook-form'
import { format } from 'date-fns'
import { ErrorMessage, FormInput } from '../../../../components/styled/uiComponents'

interface Props {
  register: UseFormRegister<any>
  control: Control<any, any>
  getValues: (name?: string) => any
  errors?: any
  metaData?: any
}

const PermanentExpat: React.FC<Props> = ({ register, errors }) => {
  const [startDate, setStartDate] = useState<string>('')

  const handleChangeStartDate = (e: any) => {
    setStartDate(e.target.value)
  }
  const today = format(new Date(), 'yyyy-MM-d')
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography variant='h4' color='primary'>
            Oleskelu ulkomailla, pysyvä
          </Typography>
          <p>
            Pysyvästi ulkomailla oleskeleva, ei tee lääkärin työtä Suomessa. Alennus -50 %
            perusjäsenmaksusta, myönnetään toistaiseksi.
          </p>
        </Grid>

        <Grid item xs={12} md={6} style={{ paddingTop: '3vh', paddingBottom: '2vh' }}>
          <FormLabel>Alkaen</FormLabel>
          <FormInput
            {...register('els_alennusalkaen')}
            type='date'
            min={today}
            onChange={handleChangeStartDate}
            value={startDate}
          />
          <ErrorMessage>{errors?.els_alennusalkaen?.message}</ErrorMessage>
        </Grid>

        <div style={{ borderTop: '1px dotted black', paddingTop: '3vh' }}>
          <Grid item xs={12} md={6}>
            <Typography variant='h6' color='primary'>
              Jäsenmaksun mahdollinen palautus
            </Typography>
            <FormLabel>
              Tilinumero jäsenmaksun mahdollista palautusta varten (IBAN-muodossa)
            </FormLabel>
            <FormInput {...register('bankAccount')} />
            <ErrorMessage>{errors?.bankAccount?.message}</ErrorMessage>
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}

export default PermanentExpat
