import React, { useState, useEffect } from 'react'
import { FormLabel, Grid, Typography } from '@mui/material'

import { Control, UseFormRegister } from 'react-hook-form'
import { format } from 'date-fns'
import { ErrorMessage, FormInput } from '../../../../components/styled/uiComponents'
import FileInput from '../../FileInput'

interface Props {
  register: UseFormRegister<any>
  control: Control<any, any>
  getValues: (name?: string) => any
  errors: any
  metaData?: any
  handleChangeDescription: (description: string) => void
}

const SicknessPay: React.FC<Props> = ({ register, handleChangeDescription, errors, control }) => {
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')

  useEffect(() => {
    handleChangeDescription('Jäljennös sairauslomatodistuksesta.')
  }, [])

  const handleChangeStartDate = (e: any) => {
    setStartDate(e.target.value)
  }

  const handleChangeEndDate = (e: any) => {
    setEndDate(e.target.value)
  }
  const today = format(new Date(), 'yyyy-MM-d')
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Typography variant='h4' color='primary'>
            Sairauspäiväraha
          </Typography>
          <p>
            Sairauspäivärahalla yhtäjaksoisesti vähintään puoli vuotta, alennus -50 %
            perusjäsenmaksusta. Hakemuksen voi lähettää puolen vuoden sairausloman jälkeen.
          </p>
        </Grid>

        <Grid item xs={12} md={6} style={{ paddingTop: '3vh', paddingBottom: '2vh' }}>
          <FormLabel>Alkaen</FormLabel>
          <FormInput
            {...register('els_alennusalkaen')}
            type='date'
            min={today}
            onChange={handleChangeStartDate}
            value={startDate}
          />
          <ErrorMessage>{errors?.els_alennusalkaen?.message}</ErrorMessage>
        </Grid>

        <Grid item xs={12} md={6} style={{ paddingBottom: '3vh' }}>
          <FormLabel>Päättyen</FormLabel>
          <FormInput
            {...register('els_alennuspaattyen')}
            type='date'
            min={today}
            onChange={handleChangeEndDate}
            value={endDate}
          />
          <ErrorMessage>{errors?.els_alennuspaattyen?.message}</ErrorMessage>
        </Grid>

        <div style={{ borderTop: '1px dotted black', paddingTop: '3vh', paddingBottom: '3vh' }}>
          <Typography variant='h6' color='primary'>
            Liitteet
          </Typography>
          <p>Jäljennös sairauslomatodistuksesta. Voit skannata tai ottaa valokuvan asiakirjasta.</p>
          <FileInput name='attachments' control={control} />
          <ErrorMessage>{errors?.attachments?.message}</ErrorMessage>
        </div>

        <div style={{ borderTop: '1px dotted black', paddingTop: '3vh' }}>
          <Grid item xs={12} md={6}>
            <Typography variant='h6' color='primary'>
              Jäsenmaksun mahdollinen palautus
            </Typography>
            <FormLabel>
              Tilinumero jäsenmaksun mahdollista palautusta varten (IBAN-muodossa)
            </FormLabel>
            <FormInput {...register('bankAccount')} />
            <ErrorMessage>{errors?.bankAccount?.message}</ErrorMessage>
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}

export default SicknessPay
