import React, { createContext, useState } from 'react'

export const MembershipContext = createContext<any>(null)

interface MembershipItem {
  els_jasentyyppi: string
  els_liitonjasenyysid: string
  yhdistys_name: string
  actionType?: number
}

const MembershipContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [contextMembership, setContextMembership] = useState<MembershipItem>()
  const [contextOrg, setContextOrg] = useState<any>()

  const value = { contextMembership, setContextMembership, contextOrg, setContextOrg }
  return <MembershipContext.Provider value={value}>{children}</MembershipContext.Provider>
}

export default MembershipContextProvider
